import React, { useEffect, useRef, useState, useMemo, MouseEventHandler } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { push } from 'react-router-redux';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import {
  getFormLiveView,
  submitFormLiveView as submitFormLiveViewAction,
  getFormCoverLetter,
  uploadAttachment,
  removeAttachments,
  resetLiveViewSubmit,
  loadInitiations,
  exchangeFormData,
  checkFormPassword,
} from 'actions/forms';
import { getAccountKey } from 'actions/auth';
import { getPerFormCapabilities as getPerFormCapabilitiesAction } from 'actions/lobby/tableApiActions';
import { getInitiations } from 'reducers/ui';
import * as selectors from 'reducers/index';
import Loader from './Loader';
import { FormRow, FormButtons } from '../../form-comps/FormLayout/FormLayout';
import style from '../../styles/shared/containerStyles.css';
import formStyle from './FormLiveView.css';
import FormImage from '../../form-comps/FormImage/FormImage';
import RawComp from '../../form-comps/RawComp/RawComp';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import {
  transformToProps,
  formStateToSubmissionData,
  attachmentsToSubmissionData,
  getReviewPages,
  getEffectiveLastPage,
} from 'utils/formLiveView/formLiveView';
import brandLogo from 'images/brand-logo.svg';
import { LiveViewCoverLetter, LiveViewElement, LiveViewForm, LiveViewPage, LiveViewRow, ControlState, FormState, ACHPaymentPayload, ReviewSectionPage, LiveViewPaymentConfig, LiveViewFormSectionBreakSetting, PaymentMethodsIcons, PaymentMethodsOpenModalHandlers, PaymentMethod, GovOSPaymentData } from 'types/liveView';
import PaymentChoiceModal from './PaymenChoiceModal';
import ProcModal from './ProcModal';
import SubmitErrorModal from './SubmitErrorModal';
import DataSyncErrorPage from 'containers/FormLiveView/DataSyncErrorPage';
import { formCapabilitiesEnum } from 'constants/tableConsts';
import { COMPANY_NAME } from 'constants/applicationCopy';
import { PaymentMethodsModal } from './PaymentMethodsModal';

import bankAccount from 'icons/bankAccount.svg';
import creditCard from 'icons/creditCard.svg';
import ACHPaymentModal from './ACHPaymentModal';
import ReviewSection from './ReviewSection';

import { COVER_LETTER_SHOWN_PREFIX, elementMap, subContainerOverrideClass } from 'constants/liveViewPage';
import { redirectToLogin, redirectAfterSubmission, scrollToFormTop, findGoogleTranslateElement, validatePage, applyCalculations, isShowHide, excludeElements, hasAcknowledgedCoverLetter, getElementValue, getNextButtonText, getPrevButtonText, getSubmitButtonText, redirectTo404 } from 'utils/formLiveView/formLiveView';
import { doPaymentFlow, getPaymentMethods, showPaymentModal } from 'utils/formLiveView/formLiveViewPayment';
import { fillDataSyncFields, getInitiationsForCurrentStep } from 'utils/formLiveView/formLiveViewDataSync';
import { renderFormErrorMessage, renderCoverLetter } from 'utils/formLiveView/renderFunctions';
/**  HEADER COMPONENT*/
import LiveViewHeader from './Header';
/** GOV OS PAY IMPORTS*/
import { PaymentsModal, ACHPaymentModal as GovOsACHPaymentModal, CardPaymentModal } from 'components/GovOsPay';
import { getGovOSPaymentMethods, doGovOsPaymentFlow } from 'utils/govOSPay/govOsPay';
import {
  FormDataGovOSPaymentConfig,
  GovOSPayModalOpenHandlers,
  PaymentType as PaymentTypeT,
} from 'types/govOsPay/govOsPaymentSettings';

import OverlayComponent from './Overlay';
import { useSubmitPaymentAmount } from 'hooks/useSubmitPaymentAmount';
import { useGetGovOsPayAmounts } from 'hooks/useGetGovOsPayAmounts';
import SummaryModal from 'components/GovOsPay/SummaryModal';

const excludeRefs = [RawComp, FormImage];

interface PropsFromDispatch {
  getFormLiveView: (formId: string, maid: string, uid: string, formAlias: string) => any,
  getPerFormCapabilities: typeof getPerFormCapabilitiesAction,
  submitFormLiveView: typeof submitFormLiveViewAction,
  getFormCoverLetter: (formId: string, maid: string) => any,
  uploadAttachment: (formId: string, file: string | Blob, id: string) => any,
  removeAttachments: (id, attachments) => any,
  resetLiveViewSubmit: () => any,
  getAccountKey: (dispatch: Function) => any,
  loadInitiations: (formId: string) => any,
  exchangeFormData: ({formId, initiationId, formData}, callback) => any,
  checkFormPassword: (formId: string, maid:string, password: string) => void,
  router: { push: (path: string) => void },
  dispatch: Function,
}

export interface Props extends PropsFromDispatch {
  accountKey: string,
  canEditForm: boolean,
  companyName: string,
  disableWizardTransition: boolean,
  logo: string,
  formId: string,
  formAlias: string,
  params: any,
  maid: string,
  isFetchingOrganizationInfo: boolean,
  isFormExpired: boolean,
  isFormSubmissionLimitReached: boolean,
  isPasswordProtected: boolean,
  hasEnteredPassword: boolean,
  isCheckingPassword: boolean,
  checkPasswordError: string | null,
  userId: string,
  coverLetter: LiveViewCoverLetter,
  formData: { form?: LiveViewForm },
  formDataError: any,
  submitResult: any,
  submitError: any,
}

export function FormLiveView({
  accountKey,
  canEditForm,
  companyName,
  disableWizardTransition,
  logo,
  address,
  phoneNumber,
  email,
  hideLetterhead,
  formId,
  formAlias,
  params,
  maid,
  isFetchingOrganizationInfo,
  userId,
  coverLetter,
  formData,
  formDataError,
  submitResult,
  submitError,
  initiations,
  router,
  dispatch,
  submitButtonSettings,
  isFormExpired = false,
  isFormSubmissionLimitReached = false,
  isPasswordProtected,
  hasEnteredPassword,
  isCheckingPassword,
  checkPasswordError,
  seoFollow,
  seoIndex,
  isAuthenticated,
  ...props
}: Props & Partial<any>) {
  const showReviewSection: boolean = formData?.form?.formSettings?.isPreviewModeOn === true;
  const isDisabledSubmitButton: boolean = submitButtonSettings?.isDisabled;
  const sectionBreakSettings: Record<number, LiveViewFormSectionBreakSetting> | undefined =
    formData?.form?.sectionBreakSettings;
  const [formState, setFormState] = useState<FormState>({ formId, formAlias } as FormState);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isProcessingDataSync, setIsProcessingDataSync] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPaymentMethodsModalOpen, setIsPaymentMethodsModalOpen] = useState<boolean>(false);
  const [isACHModalOpen, setIsACHModalOpen] = useState<boolean>(false);
  const [modalOptions, setModalOptions] = useState<{ open: boolean, amount: number }>({ open: false, amount: 0 });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [googleTranslateElement, setGoogleTranslateElement] = useState<LiveViewElement | undefined>(undefined);
  const [dataSyncError, setDataSyncError] = useState<string>('');
  const [hasRequestedFormData, setHasRequestedFormData] = useState<boolean>(false);
  const [hasRequestedInitiations, setHasRequestedInitiations] = useState<boolean>(false);
  const [hasRequestedCoverLetter, setHasRequestedCoverLetter] = useState<boolean>(false);
  const rootRef: React.MutableRefObject<HTMLElement | null> = useRef(null);
  const formStateRef: React.MutableRefObject<FormState> = useRef(formState);
  const refs: React.MutableRefObject<{}> = useRef({});
  const alwaysHideSubmitButton: boolean = formData?.form?.formSettings?.disableSubmit === true;
  const formulas = useMemo(() => new Map(), [formData]);
  const reviewPages: ReviewSectionPage[] = useMemo(() => getReviewPages(formData?.form), [formData]);
  const shouldDisplayPreviewPage: boolean = showReviewSection && !!reviewPages.length;
  const effectiveLastPage: number = getEffectiveLastPage(formData?.form, shouldDisplayPreviewPage);
  const isOnLastPage: boolean = currentPage === effectiveLastPage;
  const css: string = formData?.form?.css || '';
  const useCoverLetter: boolean = !!(coverLetter.settings?.useCoverLetter);
  const showCoverLetter: boolean = !formDataError && useCoverLetter && !hasAcknowledgedCoverLetter(formId);
  const needsPassword: boolean = !formDataError && isPasswordProtected && !hasEnteredPassword;
  const isRedirectingToDoc: boolean = formData?.form?.itemType === 'Wizard' && !disableWizardTransition;

  /** GOV OS PAY STATE */
  const [isGovOsPaymentMethodsModalOpen, setGovOsPaymentMethodsModalOpen] = useState<boolean>(false);
  const [selectedPaymentSummaryType, setSelectedPaymentSummaryType] = useState<PaymentTypeT | null>(null);
  const [isGovOsPayACHModalOpen, setGovOsPayACHModalOpen] = useState<boolean>(false);
  const [isGovOsCardModalOpen, setGovOsCardModalOpen] = useState<boolean>(false);
  const govOsPaymentCorrelationId = useMemo(() => uuidv4().replace(/-/g, ''), []);

  const setFormStateWithRef = (newFormState: FormState): void => {
    formStateRef.current = newFormState;
    setFormState(newFormState);
  };
  // Handles the 'Submit' or 'Pay & Submit' params for the button
  const [paymentAmount, requiresPayment] = useSubmitPaymentAmount(formData, formState);
  const govOsPaymentAmounts = useGetGovOsPayAmounts(formData, formState);

  useEffect(() => {
    if (formData && formData.form && !googleTranslateElement) {
      setGoogleTranslateElement(findGoogleTranslateElement(formData.form.pages));
    }
  }, [formData]);

  useEffect(() => { // on mount
    if (!maid && !isFetchingOrganizationInfo) { // no org info etc. get it.
      props.getAccountKey(dispatch);
    }
    if (maid && !formData && !hasRequestedFormData) {
      props.getFormLiveView(formId, maid, userId, formAlias);
      setHasRequestedFormData(true);
    }
    if (!!formId && !hasRequestedInitiations) {
      props.loadInitiations(formId);
      setHasRequestedInitiations(true);
    }
    if (maid && formId && !coverLetter.settings && !hasRequestedCoverLetter) {
      props.getFormCoverLetter(formId, maid); // get cover letter settings.
      setHasRequestedCoverLetter(true);
    }
  }, [maid, formId, formAlias]);

  useEffect(() => {
    if (submitError) {
      setIsSubmitting(false); // make sure submit is reopened.
    }
    // already successfully submitted, redirect to thank you page
    if (redirectAfterSubmission(submitResult, formData, formId, router, isRedirectingToDoc)) return;
    // form requires login, redirect
    if (redirectTo404(formData, formDataError, router)) return;
    if (redirectToLogin(coverLetter, formData, formDataError, formId, formAlias)) return;
  }, [formData, submitResult, formDataError, submitError]);

  useEffect(() => {
    // If the user is logged in, check if they have permissions to edit the form
    if (isAuthenticated && formId && !canEditForm) {
      props.getPerFormCapabilities([formId]);
    }
  }, [isAuthenticated, canEditForm, formId]);

  const getSubmissionData = () => [
    ...formStateToSubmissionData(formStateRef.current),
    ...attachmentsToSubmissionData(props.attachments),
  ];


  const submitForm = (payData?: any): void => {
    setIsSubmitting(true);
    props.submitFormLiveView(formId, maid, userId, getSubmissionData(), payData);
  };

  const submitFormACH = (achPayData: ACHPaymentPayload): void => {
    setIsSubmitting(true);
    props.submitFormLiveView(
      formId,
      maid,
      userId,
      getSubmissionData(),
      null,
      achPayData);
  };

  const submitFormGovOSPay = (govOsPayData: GovOSPaymentData): void => {
    setIsSubmitting(true);
    props.submitFormLiveView(
      formId,
      maid,
      userId,
      getSubmissionData(),
      undefined,
      undefined,
      govOsPayData,
    );
  };

  const handleGovOSPaySuccess = (govOsPayData: GovOSPaymentData): void => {
    // Display the success message for 2 seconds before submitting the form
    setTimeout(() => submitFormGovOSPay(govOsPayData), 2000);
  };

  const paymentIcons: PaymentMethodsIcons = { bankAccount, creditCard, debitCard: creditCard };
  const openPaymentModalHandlers: PaymentMethodsOpenModalHandlers = {
    openAchPaymentModal: () => {
      setIsPaymentMethodsModalOpen(false);
      setIsACHModalOpen(true);
    },
    openCreditCardPaymentModal: () => {
      showPaymentModal('credit_card', formData, formState, { companyName, accountKey }, submitForm, setIsModalOpen);
      setIsPaymentMethodsModalOpen(false);
    },
    openDebitCardPaymentModal: () => {
      showPaymentModal('debit_card', formData, formState, { companyName, accountKey }, submitForm, setIsModalOpen);
      setIsPaymentMethodsModalOpen(false);
    },
  };

  const openGovOSPaymentModalHandlers: GovOSPayModalOpenHandlers = {
    openGovOSACHPaymentModal: () => {
      setGovOsPaymentMethodsModalOpen(false);
      setSelectedPaymentSummaryType(null);
      setGovOsPayACHModalOpen(true);
    },
    openGovOSCardPaymentModal: () => {
      setGovOsPaymentMethodsModalOpen(false);
      setSelectedPaymentSummaryType(null);
      setGovOsCardModalOpen(true);
    },
  };

  const govOSPaymentMethods = getGovOSPaymentMethods(
    formData?.form?.govOsPayConfig,
    paymentIcons,
    govOsPaymentAmounts,
    setGovOsPaymentMethodsModalOpen,
    setSelectedPaymentSummaryType
  );

  const paymentMethods: PaymentMethod[] = getPaymentMethods(
    formData,
    formState,
    paymentIcons,
    openPaymentModalHandlers,
  );

  const closeModal = (): void => {
    setModalOptions({ ...modalOptions, open: false });
  };

  const fillDataSyncFieldsAndSetState = (initiation, result = null): void => {
    const targetFields = fillDataSyncFields(initiation, result, formState);
    setFormStateWithRef({ ...formStateRef.current, ...targetFields } as FormState);
  };

  const processFormDataExchange = (initiation, values, callback): void => {
    props.exchangeFormData({ formId, initiationId: initiation.initiationId, formData: values }, {
      success: result => {
        fillDataSyncFieldsAndSetState(initiation, result);
        setTimeout(() => {
          callback?.success();
        }, 1000); // wait for data populated on the form
      },
      failure: err => {
        console.log('Error processDataSync', err); // eslint-disable-line
        fillDataSyncFieldsAndSetState(initiation);
        if (!initiation.isRequired) {
          callback?.success();
          return;
        }
        callback?.failure(initiation.errorMessage);
      },
    });
  };

  const processDataSync = (initiationStep, callback): void => {
    const initiationsForCurrentStep = getInitiationsForCurrentStep(initiations, initiationStep);

    if (initiationsForCurrentStep.length > 0) {
      setIsProcessingDataSync(true);
      let hasLookupValue = false;
      let hasPagesInitiationValue = false;
      initiationsForCurrentStep.forEach(initiation => {
        const values = initiation.requestMappings.reduce((data, requestMapping) => {
          if (requestMapping.sourceField === 'pages') {
            hasPagesInitiationValue = true;
          }

          const elementValue = getElementValue(requestMapping.targetField, formState);
          return {
            ...data,
            ...(elementValue && {[requestMapping.sourceField]: getElementValue(requestMapping.targetField, formState)}),
          };
        }, {});

        const updatedValues = {pages: hasPagesInitiationValue ? '1' : undefined, ...values};

        if (Object.values(updatedValues).some(value => typeof value !== 'undefined')) {
          hasLookupValue = true;
          processFormDataExchange(initiation, updatedValues, callback);
        }
      });
      // If no lookup value, proceed to the next step
      if (!hasLookupValue) {
        callback?.success();
      }
    } else {
      callback?.success();
    }
  };

  const onFormControlChanged = (controlId: string) => (controlState: ControlState): void => {
    const nState = {
      ...formStateRef.current,
      [controlId]: controlState,
    };
    applyCalculations(nState as FormState, formulas);
    setFormStateWithRef(nState);
  };

  const onCoverLetterContinue: React.MouseEventHandler = (event: React.MouseEvent<Element, MouseEvent>): void => {
    event.preventDefault();
    sessionStorage.setItem(`${COVER_LETTER_SHOWN_PREFIX}${formId}`, '1');
    if (formDataError && [401, 403].includes(formDataError.status)) { // form access requires login
      location.href = `/ng/login?redirect_url=/ng/f/${formId}`;
      return;
    }
    scrollToFormTop();
    setFormStateWithRef({ ...formState });
  };

  const checkConditions = (condition, id: string): boolean => {
    if (condition) {
      const { conditionValue, conditionalCompareWith, showHideAction } = condition;
      const isCondTrue = isShowHide(conditionalCompareWith.replace('[]', ''), conditionValue, formData, formState);
      if (showHideAction === 'show') {
        if (!isCondTrue) {
          if (formState[id]) {
            const newState = { ...formState, [id]: null } as FormState;
            applyCalculations(newState, formulas);
            setFormStateWithRef({ ...newState });
          }
        }
        return isCondTrue;
      } else if (isCondTrue) {
        if (formState[id]) {
          const newState = { ...formState, [id]: null } as FormState;
          applyCalculations(newState, formulas);
          setFormStateWithRef({ ...newState });
        }
        return false;
      }
    }
    return true;
  };

  const checkShowSubmitCondition = (): boolean => {
    if (submitButtonSettings?.showHideCondition) {
      const {
        conditionValue,
        conditionalCompareWith,
        showHideAction,
      } = submitButtonSettings?.showHideCondition;

      const isCondTrue = isShowHide(conditionalCompareWith.replace('[]', ''), conditionValue, formData, formState);

      if (showHideAction === 'show') {
        return isCondTrue;
      }

      return !isCondTrue;
    }

    return true;
  };

  const checkNextBtnConditions: boolean =
    useMemo(() => {
      if (sectionBreakSettings && sectionBreakSettings[currentPage]?.nextBtnShowHideCondition) {
        const {
          conditionValue,
          conditionalCompareWith,
          showHideAction,
        } = sectionBreakSettings[currentPage]?.nextBtnShowHideCondition;

        const isCondTrue = isShowHide(conditionalCompareWith.replace('[]', ''), conditionValue, formData, formState);

        if (showHideAction === 'show') {
          return isCondTrue;
        }

        return !isCondTrue;
      }


      return true;
    }, [currentPage, sectionBreakSettings, formState]);

  useEffect(() => {
    /**
     * remove values that were set on subsequent pages
     * in case if section break was conditionally hidden
     * because we don't want to submit hidden values
     */
    if (!isOnLastPage && !checkNextBtnConditions) {
      const pages: LiveViewPage[] = formData?.form?.pages || [];
      for (let i = currentPage + 1; i < pages.length; i++) {
        const elements: LiveViewElement[] = pages[i].rows.flatMap((row: LiveViewRow) => row.elements);
        const elementIds: string[] = elements.map((element: LiveViewElement) => element.id);
        const nState: FormState = {
          ...formStateRef.current,
        };
        elementIds.forEach((id: string) => delete nState[id]);
        applyCalculations(nState as FormState, formulas);
        setFormStateWithRef(nState);
      }
    }
  }, [checkNextBtnConditions]);

  const showPrev: boolean =
    currentPage > 0 ||
    !!dataSyncError;

  const showNext: boolean =
    !isOnLastPage &&
    !dataSyncError &&
    checkNextBtnConditions;

  const showSubmit: boolean =
    (isOnLastPage || !checkNextBtnConditions) &&
    !dataSyncError &&
    !alwaysHideSubmitButton &&
    !isDisabledSubmitButton;

  const disablePrev: boolean =
    !showPrev ||
    isProcessingDataSync ||
    isSubmitting ||
    isModalOpen;

  const disableNext: boolean =
    !showNext ||
    isProcessingDataSync ||
    isSubmitting ||
    isModalOpen;

  const disableSubmit: boolean =
    !showSubmit ||
    alwaysHideSubmitButton ||
    isDisabledSubmitButton ||
    isProcessingDataSync ||
    isSubmitting ||
    isModalOpen ||
    (!isOnLastPage && checkNextBtnConditions) ||
    !!dataSyncError ||
    !checkShowSubmitCondition() ||
    isFormExpired ||
    isFormSubmissionLimitReached ||
    needsPassword;

  const onPageNext = (): void => {
    if (validatePage(refs, currentPage)) {
      processDataSync(`page_${currentPage}`, {
        success: () => {
          setIsProcessingDataSync(false);
          scrollToFormTop();
          !isOnLastPage && setCurrentPage(currentPage + 1);
        },
        failure: errorMessage => {
          setIsProcessingDataSync(false);
          setDataSyncError(errorMessage);
        },
      });
    }
  };

  const onPagePrev = (): void => {
    const prevPage = currentPage - 1;
    setDataSyncError('');
    scrollToFormTop();
    prevPage > -1 && setCurrentPage(prevPage);
  };

  const onSubmit = (): void => {
    if (!isOnLastPage && checkNextBtnConditions) {
      onPageNext();
      return;
    }

    if (disableSubmit || !validatePage(refs, currentPage)) {
      return;
    }

    processDataSync('submit', {
      success: () => {
        const { form } = formData || {};
        const govOsPaymentConfig: undefined | null | FormDataGovOSPaymentConfig = form?.govOsPayConfig;
        const spreedlyPaymentConfig: undefined | null | LiveViewPaymentConfig = form?.paymentConfig;

        setIsProcessingDataSync(false);

        if (
          !(spreedlyPaymentConfig || govOsPaymentConfig) // No payment on form
          || isRedirectingToDoc // Payment happens later for wizards
        ) {
          submitForm();
          return;
        }

        if (spreedlyPaymentConfig && !govOsPaymentConfig) {
          doPaymentFlow(
            paymentMethods,
            formData,
            formState,
            submitForm,
            { companyName, accountKey },
            {
              setModalOptions,
              setIsPaymentMethodsModalOpen,
              setIsACHModalOpen,
              setIsModalOpen,
            }
          );
          return;
        }

        if (govOsPaymentConfig && !spreedlyPaymentConfig) {
          doGovOsPaymentFlow(govOsPaymentConfig, paymentAmount, submitForm, {
            setGovOsPaymentMethodsModalOpen,
            setSelectedPaymentSummaryType,
            setGovOsPayACHModalOpen,
            setGovOsCardModalOpen,
          });
          return;
        }
      },
      failure: errorMessage => {
        setIsProcessingDataSync(false);
        setDataSyncError(errorMessage);
      },
    });
  };

  const onFormElemSubmit: React.EventHandler<React.FormEvent<HTMLFormElement>> = submitEvent => {
    submitEvent.preventDefault();
    onSubmit();
  };

  const renderFormElement = ({
    id,
    elementType,
    label,
    showHideCondition,
    ...rest
  }: LiveViewElement): JSX.Element | null => {
    const [Comp, defaultProps] = elementMap[elementType] || elementMap.default;
    const eProps = transformToProps({ id, label, elementType, ...rest } as LiveViewElement, defaultProps);

    // Pass Form id and master account ID to all elements
    eProps.formId = formId || '';
    eProps.maid = maid || '';

    if (elementType === 'upload') {
      eProps.uploadAttachment = file => props.uploadAttachment(formId, file, id);
      eProps.removeAttachments = att => props.removeAttachments(id, att);
      eProps.attachments = props.attachments[id] || [];
    }

    if (eProps.formula) { // gather all formulas
      formulas.set(id, { formula: eProps.formula, Comp, eProps });
    }

    if (Comp.willBeNull && Comp.willBeNull(eProps)) return null;
    return checkConditions(showHideCondition, id) ? (
      <Comp
        key={id}
        id={id}
        updateForm={onFormControlChanged(id)}
        fieldState={formState[id]}
        ref={!excludeRefs.includes(Comp) ? (el => {
          refs.current[currentPage] = refs.current[currentPage] || {};
          refs.current[currentPage][id] = el;
          return refs.current[currentPage][id];
        }) : null}
        {...eProps}
      />) : null;
  };

  const renderFormRow = (row: LiveViewRow, index: number): JSX.Element => {
    const rowId = (row.rowId || (row.elements.length === 1 ? row.elements[0].id : 'row')) + `_${index}`;
    return (<FormRow key={rowId} row={excludeElements(row)} formElemRenderer={renderFormElement} />);
  };

  const renderFormPage = (page: LiveViewPage): JSX.Element[] => page?.rows.map(renderFormRow);

  const renderReviewPage = (): JSX.Element => {
    const reviewSections: JSX.Element[] = reviewPages.map((reviewPage: ReviewSectionPage, index: number) =>
      <ReviewSection
        key={reviewPage.pageIndex}
        page={reviewPage.page}
        pageIndex={reviewPage.pageIndex}
        formState={formState}
        setCurrentPage={setCurrentPage}
        opened={index === 0}
      />);
    return (<div>
      <h2>Review and confirm the data entered</h2>
      {reviewSections}
    </div>);
  };

  const renderFormElements = (): JSX.Element | JSX.Element[] => {
    if (isProcessingDataSync) {
      return (<Loader />);
    }

    if (formData?.form && coverLetter
      && (hasAcknowledgedCoverLetter(formId)
        || (coverLetter.settings && !coverLetter.settings.useCoverLetter)
      )) { // make sure no blinks happen during first render
      if (isOnLastPage && shouldDisplayPreviewPage) {
        return renderReviewPage();
      }
      return renderFormPage(formData.form.pages[currentPage]);
    }
    if (formDataError) {
      return renderFormErrorMessage('Sorry, this form could not be found.', formStyle);
    }
    return (<Loader />);
  };

  const onJustSubmit: MouseEventHandler = e => {
    e.preventDefault();
    submitForm();
    closeModal();
  };

  const onPayAndSubmit: MouseEventHandler = (e: React.MouseEvent<Element, MouseEvent>): void => {
    e.preventDefault();
    closeModal();

    const paymentConfig: LiveViewPaymentConfig | null | undefined = formData?.form?.paymentConfig;

    if (!paymentConfig) {
      return;
    }

    const { achEnabled, ccEnabled, debitEnabled } = paymentConfig;

    if (paymentMethods.length > 1) {
      setIsPaymentMethodsModalOpen(true);
    } else if (achEnabled && !ccEnabled && !debitEnabled) {
      setIsACHModalOpen(true);
    } else if (!achEnabled && ccEnabled && !debitEnabled) {
      showPaymentModal('credit_card', formData, formState, { companyName, accountKey }, submitForm, setIsModalOpen);
    } else if (!achEnabled && !ccEnabled && debitEnabled) {
      showPaymentModal('debit_card', formData, formState, { companyName, accountKey }, submitForm, setIsModalOpen);
    }
  };

  const submitButtonText: string = useMemo(() => {
    const settingsLabel = submitButtonSettings?.label || '';
    return getSubmitButtonText(settingsLabel, isRedirectingToDoc, paymentAmount, requiresPayment);
  }, [paymentAmount, requiresPayment, submitButtonSettings?.label]);

  const nextButtonText: string = useMemo(() => getNextButtonText(sectionBreakSettings, currentPage),
    [currentPage, sectionBreakSettings]);

  const prevButtonText: string = useMemo(() => getPrevButtonText(sectionBreakSettings, currentPage),
    [currentPage, sectionBreakSettings]);

  const renderMainForm = (): JSX.Element => <>
    {googleTranslateElement && <FormRow key={'gt-element'} row={{ elements: [googleTranslateElement!], rowId: 'gt-element' }} formElemRenderer={renderFormElement} />}
    {showCoverLetter ? renderCoverLetter(coverLetter, onCoverLetterContinue) : renderFormElements()}
  </>;

  return (
    <section className={classNames(style.Container, formStyle.FormLiveViewContainer, 'form_container')} ref={rootRef}>
      <LiveViewHeader formData={formData} logoUrl={logo} canEditForm={canEditForm} />
      <main className={classNames(formStyle.FormLiveViewSubContainer, subContainerOverrideClass)}>
        <form id='main_form' onSubmit={onFormElemSubmit} className={formStyle.FormLiveViewForm} noValidate>
          {(isFormSubmissionLimitReached || isFormExpired || needsPassword) &&
          <OverlayComponent
            isCheckingPassword={isCheckingPassword}
            isFormExpired={isFormExpired}
            isFormSubmissionLimitReached={isFormSubmissionLimitReached}
            requiresPassword={needsPassword}
            checkPassword={password => props.checkFormPassword(formId, maid, password)}
            checkPasswordError={checkPasswordError}
          />}
          <fieldset className={formStyle.FormLiveViewFieldSet}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {dataSyncError ?
              <DataSyncErrorPage errorMessage={dataSyncError} /> : renderMainForm()}
          </fieldset>
        </form>
      </main>
      <footer className={classNames(formStyle.FormSideBar, 'form_sidebar')}>
        {!hideLetterhead && <div className={classNames(formStyle.FormSideBarBrandContainer, 'form_sidebar_brand_container')}>
          <img className={classNames(formStyle.FormSideBarLogo, 'form_sidebar_logo')} src={logo} alt={companyName + ' logo'} />
          <br />
          <p>{companyName}</p>
          {email && <span className={formStyle.letterheadText}>{email}</span>}
          {address && <span className={formStyle.letterheadText}>{address}</span>}
          {phoneNumber && <span className={formStyle.letterheadText}>{phoneNumber}</span>}
        </div>}
        <FormButtons
          hideAll={showCoverLetter || !formData}
          nextText={nextButtonText}
          prevText={prevButtonText}
          onPrev={onPagePrev}
          onNext={onPageNext}
          onSubmit={onSubmit}
          disablePrev={disablePrev}
          disableNext={disableNext}
          disableSubmit={disableSubmit}
          showPrev={showPrev}
          showNext={showNext}
          showSubmit={showSubmit}
          submitText={submitButtonText}
          disabledText={!checkShowSubmitCondition() && submitButtonSettings?.altText}
        />
      </footer>
      <Helmet>
        <title>{`${COMPANY_NAME}: ${formData?.form?.formName || 'Form'}`}</title>
        {seoIndex && seoFollow && <meta name='robots' content='index, follow' />}
        {seoIndex && !seoFollow && <meta name='robots' content='index' />}
        {!seoIndex && seoFollow && <meta name='robots' content='follow' />}
        <script src='https://core.spreedly.com/iframe/express-2.min.js' />
        <style type='text/css' id={`custom_style_${formId}`}>{css || '/*custom form css here*/'}</style>
      </Helmet>
      <PaymentChoiceModal
        amount={modalOptions.amount}
        open={modalOptions.open}
        onJustSubmit={onJustSubmit}
        onPayAndSubmit={onPayAndSubmit}
        onClose={closeModal}
      />
      <PaymentMethodsModal
        open={isPaymentMethodsModalOpen}
        closeModalHandler={() => setIsPaymentMethodsModalOpen(false)}
        paymentMethods={paymentMethods}
        isPaymentOptional={formData?.form?.paymentConfig?.paymentOptional || false}
        onJustSubmit={onJustSubmit}
      />
      <ACHPaymentModal
        open={isACHModalOpen}
        closeModalHandler={() => setIsACHModalOpen(false)}
        paymentConfig={formData?.form?.paymentConfig}
        formState={formState}
        onSubmit={(achPayData: ACHPaymentPayload) => submitFormACH(achPayData)}
      />
      {/* GOV OS PAY MODALS*/}
      <PaymentsModal
        open={isGovOsPaymentMethodsModalOpen}
        closeModal={() => setGovOsPaymentMethodsModalOpen(false)}
        govOSPaymentMethods={govOSPaymentMethods}
        paymentConfig={formData?.form?.govOsPayConfig}
      />

      <SummaryModal
        accountName={companyName}
        amounts={govOsPaymentAmounts}
        closeModal={() => setSelectedPaymentSummaryType(null)}
        selectedPaymentSummaryType={selectedPaymentSummaryType}
        paymentObjects={govOSPaymentMethods}
        openGovOSPaymentModalHandlers={openGovOSPaymentModalHandlers}
      />

      <GovOsACHPaymentModal
        open={isGovOsPayACHModalOpen}
        closeModal={() => setGovOsPayACHModalOpen(false)}
        amounts={govOsPaymentAmounts}
        paymentCorrelationId={govOsPaymentCorrelationId}
        paymentConfig={formData?.form?.govOsPayConfig}
        onSuccess={handleGovOSPaySuccess}
        digitalServiceFeeConfig={formData?.form?.digitalServiceFeeConfig}
      />

      <CardPaymentModal
        open={isGovOsCardModalOpen}
        closeModal={() => setGovOsCardModalOpen(false)}
        amounts={govOsPaymentAmounts}
        paymentCorrelationId={govOsPaymentCorrelationId}
        paymentConfig={formData?.form?.govOsPayConfig}
        onSuccess={handleGovOSPaySuccess}
        digitalServiceFeeConfig={formData?.form?.digitalServiceFeeConfig}
      />

      <ProcModal open={isSubmitting} />
      <SubmitErrorModal open={!!submitError} error={submitError} onClose={props.resetLiveViewSubmit} />
    </section>
  );
}

const mapStateToProps = (state, { router }) => {
  const {
    auth = {},
    forms = {},
    ui = {},
    splitio = {},
  }: { forms: any, auth: any, ui: any, splitio: any } = state;

  const isAuthenticated = auth.isAuthenticated || false;
  const formData = forms?.liveView?.form;
  const formId = router.params?.formID || formData?.form.formId;
  const letterheadSettings = formData?.form?.formSettings?.letterheadSettings || {};
  const formSettings = formData?.form?.formSettings;
  const { expirationDate, submissionCount, seoSettings} = formSettings || {};
  // ENG-6030 | Enforce expiration date
  // Convert expirationDate to Moment object
  const expirationMoment = moment(expirationDate);
  // Check if the current date is after the expiration date (considering UTC)
  const isFormExpired: boolean = expirationMoment.isValid() && moment().isAfter(expirationMoment);
  // ENG-6239 | Enforce submission limit
  const {limitSubmissions, totalActiveAndNonArchivedSubmissions } = submissionCount || {};
  const isLimitSubmissionsValid = limitSubmissions !== null && limitSubmissions !== undefined && limitSubmissions > 0;
  const isFormSubmissionLimitReached: boolean = isLimitSubmissionsValid &&
    totalActiveAndNonArchivedSubmissions >= limitSubmissions;
  // ENG-6269 SEO Settings
  const {seoFollow, seoIndex} = seoSettings || {};
  const {
    companyName = '',
    overrideAccountInfo = false,
    logo = '',
    address = '',
    email = '',
    phoneNumber = '',
    hideLetterhead = false,
  } = letterheadSettings;

  return {
    formId,
    formAlias: router.params.formAlias || formData?.form.formAlias,
    attachments: forms.attachments,
    coverLetter: forms.coverLetter || null,
    formData: formData || null,
    formDataError: forms?.liveView?.error || null,
    submitResult: forms?.formSubmit?.submitResult || null,
    submitError: forms?.formSubmit?.submitError || null,
    userId: auth?.profile?.auid || '',
    maid: auth.maid || '',
    isFetchingOrganizationInfo: auth?.isFetchingOrganizationInfo,
    accountKey: auth.accountKey,
    canEditForm: selectors.checkFormCapability(state, formId, formCapabilitiesEnum.Edit),
    companyName: overrideAccountInfo && companyName ? companyName : auth.companyName,
    logo: overrideAccountInfo && logo ? logo : auth.organizationLogo || brandLogo,
    address: overrideAccountInfo && address ? letterheadSettings?.address : auth.address,
    email: overrideAccountInfo && email ? email : auth.companyEmail,
    phoneNumber: overrideAccountInfo && phoneNumber || null,
    hideLetterhead,
    disableWizardTransition: selectTreatmentValue(splitio, 'NG_WIZARD_HACK') === 'on',
    submitButtonSettings: selectTreatmentValue(splitio, 'SUBMIT_BUTTON_SETTINGS') === 'on' &&
      formData?.form?.submitButtonSettings,
    initiations: getInitiations(ui),
    isFormExpired,
    isFormSubmissionLimitReached,
    isPasswordProtected: formData?.form?.formSettings?.isRequiresPassword === true,
    hasEnteredPassword: selectors.getHasEnteredCorrectFormPassword(state),
    isCheckingPassword: selectors.getIsCheckingFormPassword(state),
    checkPasswordError: selectors.getCheckFormPasswordError(state),
    seoFollow,
    seoIndex,
    isAuthenticated,
  };
};


const mapDispatchToProps = (dispatch: Dispatch<any>): PropsFromDispatch => ({
  ...bindActionCreators({
    getFormLiveView,
    submitFormLiveView: submitFormLiveViewAction,
    getFormCoverLetter,
    getPerFormCapabilities: getPerFormCapabilitiesAction,
    uploadAttachment,
    removeAttachments,
    resetLiveViewSubmit,
    getAccountKey,
    loadInitiations,
    exchangeFormData,
    checkFormPassword,
  }, dispatch),
  router: bindActionCreators({ push }, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormLiveView);
