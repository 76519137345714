import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import submissionRuleBuilder from './submissionRuleBuilder';
import conditionals, * as conditionalSelectors from 'reducers/conditionals';
import coverLetterSettings from './coverLetterSettings';
import paymentConfiguration, * as paymentConfigurationSelectors from './paymentConfiguration';
import govOsPaymentConfiguration, * as govOsPaymentConfigurationSelectors from './govOsPaymentConfiguration';
import paymentSystem, * as paymentSystemSelectors from './paymentSystem';
import paymentSettings, * as paymentSettingsSelectors from './paymentSettings';
import govOsPaymentSettings, * as govOsPaymentSettingsSelectors from './govOsPaymentSettings';
import permissions from 'reducers/permissions';
import auth, * as authSelectors from 'reducers/auth';
import dataDictionaries, * as dataDictionarySelectors from './dataDictionaries';
import features from 'reducers/features';
import rules, * as ruleSelectors from 'reducers/rules';
import emailFields from 'reducers/emailFields';
import signerFields from 'reducers/signersFields';
import formFields, * as formFieldSelectors from 'reducers/formFields';
import stages from 'reducers/stages';
import tags from 'reducers/tags';
import users, * as userSelectors from 'reducers/users';
import ui, * as uiSelectors from './ui';
import { splitReducer } from '@splitsoftware/splitio-redux';
import { getExistingParticipantsWithErrors, getGroupsWithErrors, getGroupWithErrors, getParticipantWithErrors, getSubmitterWithErrors } from 'utils/participants';
import { getConditionalFieldsForParticipant, getValidatedConditionalsForAllParticipants, getValidatedConditionalsForParticipant } from 'utils/conditionals';


export default combineReducers({
  auth,
  conditionals,
  dataDictionaries,
  emailFields,
  formFields,
  features,
  coverLetterSettings,
  paymentConfiguration,
  govOsPaymentConfiguration,
  paymentSystem,
  paymentSettings,
  govOsPaymentSettings,
  permissions,
  rules,
  signerFields,
  stages,
  submissionRuleBuilder,
  tags,
  users,
  ui,
  splitio: splitReducer,
});

/* Auth */
export const getMaid = state =>
  authSelectors.getMaid(state.auth);

/* Payment */

export const getIsPaymentGatewaySidebarOpen = state =>
  uiSelectors.getIsPaymentGatewaySidebarOpen(state.ui);

export const getPaymentFormId = state => uiSelectors.getPaymentFormId(state.ui);

export const getConfirmationConfig = state =>
  uiSelectors.getConfirmationConfig(state.ui);

export const getShouldRenderConfirmation = state =>
  uiSelectors.getShouldRenderConfirmation(state.ui);

export const getPaymentGateways = state =>
  paymentConfigurationSelectors.getPaymentGateways(state.paymentConfiguration);

export const getChosenGateway = state =>
  paymentConfigurationSelectors.getChosenGateway(state.paymentConfiguration);

export const getIsPaymentSystemPending = state =>
  paymentSystemSelectors.getIsPending(state.paymentSystem);

export const getShouldRenderSuccessMessage = state =>
  paymentSystemSelectors.getShouldRenderSuccessMessage(state.paymentSystem);

export const getIsPaymentConfigurationApproved = state =>
  paymentConfigurationSelectors.getIsPaymentConfigurationApproved(state.paymentConfiguration);

export const isSaveSnackbarOpen = state =>
  paymentSettingsSelectors.isSaveSnackbarOpen(state.paymentSettings);

export const getPaymentFormFields = state =>
  paymentSettingsSelectors.getFormFields(state.paymentSettings);

export const getPaymentEmailFields = state =>
  paymentSettingsSelectors.getFormEmailFields(state.paymentSettings);

export const getPaymentToken = state =>
  paymentConfigurationSelectors.getPaymentToken(state.paymentConfiguration);

export const getPaymentConfiguration = state =>
  paymentConfigurationSelectors.getPaymentConfiguration(state.paymentConfiguration);

export const getIsConfigReady = state =>
  paymentConfigurationSelectors.getIsConfigReady(state.paymentConfiguration);

export const getAuthenticatingError = state =>
  paymentConfigurationSelectors.getAuthenticatingError(state.paymentConfiguration);

export const getFormHasRecaptcha = state =>
  paymentSettingsSelectors.getFormHasRecaptcha(state.paymentSettings);

/* GovOS Payments */

export const getIsGovOsPaymentGatewaySidebarOpen = state => uiSelectors.isGovOsPayModalVisible(state.ui);

export const getGovOsPaymentFormId = state => uiSelectors.getGovOsPaymentFormId(state.ui);

// export const getConfirmationConfig = state =>
//   uiSelectors.getConfirmationConfig(state.ui);

// export const getShouldRenderConfirmation = state =>
//   uiSelectors.getShouldRenderConfirmation(state.ui);

// export const getIsPaymentSystemPending = state =>
//   paymentSystemSelectors.getIsPending(state.paymentSystem);

// export const getShouldRenderSuccessMessage = state =>
//   paymentSystemSelectors.getShouldRenderSuccessMessage(state.paymentSystem);

export const getGovOsPaymentProviders = state =>
  govOsPaymentConfigurationSelectors.getPaymentProviders(state.govOsPaymentConfiguration);

export const isGovOsPaySaveSnackbarOpen = state =>
  govOsPaymentSettingsSelectors.isSaveSnackbarOpen(state.govOsPaymentSettings);

export const getGovOsPaymentFormFields = state =>
  govOsPaymentSettingsSelectors.getFormFields(state.govOsPaymentSettings);

export const getGovOsPaymentFormInputFields = state =>
  govOsPaymentSettingsSelectors.getFormInputFields(state.govOsPaymentSettings);

// export const getPaymentEmailFields = state =>
//   paymentSettingsSelectors.getFormEmailFields(state.govOsPaymentSettings);

export const getGovOsPaymentConfiguration = state =>
  govOsPaymentConfigurationSelectors.getPaymentConfiguration(state.govOsPaymentConfiguration);

export const getIsGovOsPayConfigReady = state =>
  govOsPaymentConfigurationSelectors.getIsConfigReady(state.govOsPaymentConfiguration);

export const getIsProviderListReady = state =>
  govOsPaymentConfigurationSelectors.getIsProviderListReady(state.govOsPaymentConfiguration);

export const getIsGovOsPaySidebarLoading = createSelector(
  [getIsGovOsPayConfigReady, getIsProviderListReady],
  (isConfigReady, isProviderListReady) => !isConfigReady || !isProviderListReady
);

// export const getFormHasRecaptcha = state =>
//   paymentSettingsSelectors.getFormHasRecaptcha(state.govOsPaymentSettings);

/* APB */
export const isApbVisible = state => uiSelectors.isApbVisible(state.ui);

export const getVisibleEmailRuleId = state => uiSelectors.getVisibleEmailRuleId(state.ui);

export const isApbLoading = state => uiSelectors.isApbLoading(state.ui);

export const getApbFormId = state => uiSelectors.getApbFormId(state.ui);

/* APB Rules */
export const getRuleState = state => ruleSelectors.getRuleState(state.rules);

export const getRulesForForm = (state, formId) =>
  ruleSelectors.getRulesForForm(state.rules, formId);

export const getRulesForCurrentForm = createSelector(
  [getRuleState, getApbFormId],
  (ruleState, formId) => (formId ? ruleSelectors.getRulesForForm(ruleState, formId) : [])
);

export const getFormEntities = state => {
  const formId = getApbFormId(state) || '';
  return {
    emailFields: state.emailFields[formId],
    signerFields: state.signerFields[formId],
    formFields: state.formFields[formId],
    users: state.users,
  };
};


/* CONDITIONALS */
export const getConditionalsState = state => conditionalSelectors.getConditionalsState(state.conditionals);

export const getConditionalsStateBeforeEditingParticipant = state =>
  conditionalSelectors.getConditionalsStateBeforeEditingParticipant(state.conditionals);

export const getHaveConditionalsChangedSincePreviousState = state =>
  conditionalSelectors.getHaveConditionalsChangedSincePreviousState(state.conditionals);


/* Data Sync */
export const getIsDataSyncSidebarOpen = state =>
  uiSelectors.getIsDataSyncSidebarOpen(state.ui);

export const getInitialConfig = state =>
  uiSelectors.getInitialConfig(state.ui);

export const getCurrentConfig = state =>
  uiSelectors.getCurrentConfig(state.ui);


/* Participants */
export const getIsParticipantsSidebarOpen = state =>
  uiSelectors.getIsParticipantsSidebarOpen(state.ui);

export const getParticipantsFormId = state =>
  uiSelectors.getParticipantsFormId(state.ui);

export const getParticipantsErrors = state =>
  uiSelectors.getParticipantsErrors(state.ui);

export const getIsAddParticipantTabVisible = state =>
  uiSelectors.getIsAddParticipantTabVisible(state.ui);

export const getParticipantType = state =>
  uiSelectors.getParticipantType(state.ui);

export const getCurrentParticipantBeforeValidation = state =>
  uiSelectors.getCurrentParticipant(state.ui);

export const getExistingParticipantsBeforeValidation = state =>
  uiSelectors.getExistingParticipants(state.ui);

export const getIsEditEmailUiVisible = state =>
  uiSelectors.getIsEditEmailUiVisible(state.ui);

export const getEmailTypeBeingEdited = state =>
  uiSelectors.getEmailTypeBeingEdited(state.ui);

export const getSubmitterBeforeValidation = state =>
  uiSelectors.getSubmitter(state.ui);

export const getIncludeLegalDisclaimer = state =>
  uiSelectors.getIncludeLegalDisclaimer(state.ui);

export const getSendSignatureReminders = state =>
  uiSelectors.getSendSignatureReminders(state.ui);

export const getHasCurrentParticipantChanged = state => uiSelectors.getHasCurrentParticipantChanged(state.ui);

export const getIndexOfParticipantBeingEdited = state => uiSelectors.getIndexOfParticipantBeingEdited(state.ui);

export const getCurrentEmailSetting = state => uiSelectors.getCurrentEmailSetting(state.ui);

export const getHasCurrentEmailSettingChanged = state => uiSelectors.getHasCurrentEmailSettingChanged(state.ui);

export const getHasParticipantsDataChanged = state => uiSelectors.getHasParticipantsDataChanged(state.ui);

export const getAreParticipantsLoading = state => uiSelectors.getAreParticipantsLoading(state.ui);

export const getIsAddGroupTabVisible = state => uiSelectors.getIsAddGroupTabVisible(state.ui);

export const getCurrentGroupBeforeValidation = state => uiSelectors.getCurrentGroup(state.ui);

export const getParallelGroupsBeforeValidation = state => uiSelectors.getParallelGroups(state.ui);

export const getIndexOfGroupBeingEdited = state => uiSelectors.getIndexOfGroupBeingEdited(state.ui);

export const getHasCurrentGroupChanged = state => uiSelectors.getHasCurrentGroupChanged(state.ui);

export const getIsLabelInputInFocus = state => uiSelectors.getIsLabelInputInFocus(state.ui);

export const getIsDataDictionarySidebarVisible = state => uiSelectors.isDataDictionarySidebarVisible(state.ui);

/* USERS */
export const getUserMap = state => userSelectors.getUserMap(state.users);
/* GOV OS PAY */
export const getIsGovOsPayModalVisible = state => uiSelectors.isGovOsPayModalVisible(state.ui);

/* FORM FIELDS */
export const getFormFieldMap = state =>
  formFieldSelectors.getFormFieldsState(state.formFields);

export const getFormFieldsForCurrentForm = createSelector(
  [getFormFieldMap, getPaymentFormId],
  (formFieldMap, formId) =>
    formFieldSelectors.getFormFieldsForForm(formFieldMap, formId)
);

/* DATA DICTIONARIES */
export const getDataDictionaryMappings = state =>
  dataDictionarySelectors.getDDMappings(state.dataDictionaries);

export const getDDMappingsForCurrentForm = createSelector(
  [getDataDictionaryMappings, getPaymentFormId],
  (mappings, formId) => mappings[formId] || [],
);


/* Composed Selectors */
export const getRuleForEmailEditor = createSelector(
  [getRulesForCurrentForm, getVisibleEmailRuleId],
  (rulesForForm, visibleRuleId) => rulesForForm.find(rule => rule.ruleId === visibleRuleId)
);

export const isParticipantsSidebarLoading = createSelector(
  [
    getAreParticipantsLoading,
    getUserMap,
    getFormFieldMap,
    getParticipantsFormId,
    getConditionalsState,
  ],
  (
    areParticipantsLoading,
    userMap,
    formFieldMap,
    participantsFormId,
    conditionalsState,
  ) => {
    if (areParticipantsLoading) return true;
    if (Object.keys(userMap).length === 0) return true;
    if (!formFieldMap[participantsFormId]) return true;
    if (!conditionalsState.rules[participantsFormId]) return true;
    return false;
  },
);

export const getSubmitter = createSelector(
  [getSubmitterBeforeValidation, getUserMap, getParallelGroupsBeforeValidation],
  (submitter, userMap, groups) => getSubmitterWithErrors(submitter, userMap, groups),
);

export const getExistingParticipants = createSelector(
  [getSubmitter, getExistingParticipantsBeforeValidation, getParallelGroupsBeforeValidation, getUserMap],
  (submitter, existingParticipants, groups, userMap) =>
    getExistingParticipantsWithErrors(existingParticipants, submitter, userMap, groups),
);

export const getCurrentParticipant = createSelector(
  [
    getCurrentParticipantBeforeValidation,
    getSubmitter,
    getExistingParticipants,
    getParallelGroupsBeforeValidation,
    getUserMap,
  ],
  (currentParticipant, submitter, existingParticipants, groups, userMap) =>
    getParticipantWithErrors(
      currentParticipant,
      submitter,
      existingParticipants,
      groups,
      userMap
    ),
);

export const getConditionalsForCurrentForm = createSelector(
  [getConditionalsState, getParticipantsFormId],
  (conditionalsState, formId) => conditionalSelectors.getConditionalsForForm(conditionalsState, formId)
);

export const getConditionalFieldsForCurrentParticipant = createSelector(
  [
    getCurrentParticipant,
    getParticipantsFormId,
    getFormFieldMap,
    getSubmitter,
    getExistingParticipants,
  ],
  (currentParticipant, formId, formFieldMap, submitter, existingParticipants) =>
    getConditionalFieldsForParticipant(
      formFieldMap[formId] || [],
      currentParticipant.participationOrder,
      submitter,
      existingParticipants
    )
);

export const getConditionalsForCurrentParticipant = createSelector(
  [getConditionalsState, getParticipantsFormId, getCurrentParticipant],
  (conditionalsState, formId, currentParticipant) =>
    conditionalSelectors.getConditionalsForParticipant(conditionalsState, formId, currentParticipant),
);

export const getCurrentGroup = createSelector(
  [getCurrentGroupBeforeValidation, getExistingParticipants],
  (currentGroup, existingParticipants) => getGroupWithErrors(currentGroup, existingParticipants),
);

export const getParallelGroups = createSelector(
  [getParallelGroupsBeforeValidation, getExistingParticipants],
  (parallelGroups, existingParticipants) => getGroupsWithErrors(parallelGroups, existingParticipants),
);


export const getValidatedConditionalsForCurrentForm = createSelector(
  [
    getConditionalsForCurrentForm,
    getFormFieldsForCurrentForm,
    getSubmitter,
    getExistingParticipants,
    getParallelGroups,
  ],
  (
    conditionalsForCurrentForm,
    formFieldsForCurrentForm,
    submitter,
    existingParticipants,
    groups,
  ) => getValidatedConditionalsForAllParticipants(
    conditionalsForCurrentForm,
    formFieldsForCurrentForm,
    submitter,
    existingParticipants,
    groups,
  )
);

export const getValidatedConditionalsForCurrentParticipant = createSelector(
  [
    getConditionalsForCurrentParticipant,
    getConditionalFieldsForCurrentParticipant,
    getCurrentParticipant,
    getParallelGroups,
  ],
  (
    conditionalsForCurrentParticipant,
    conditionalFieldsForCurrentParticipant,
    currentParticipant,
    groups,
  ) =>
    getValidatedConditionalsForParticipant(
      conditionalsForCurrentParticipant,
      conditionalFieldsForCurrentParticipant,
      currentParticipant.participationOrder,
      groups,
    )
);
