import { Dispatch, SetStateAction } from 'react';
import Big from 'big.js';
import { FormState, PaymentMethodsIcons } from 'types/liveView';
import { addFeeType, getFieldAmount } from 'utils/formLiveView/formLiveView';
import {
  FormDataGovOSPaymentConfig,
  GovOsPaymentObject,
  ModalStateSetters,
  PaymentType as PaymentTypeT,
  PaymentAmounts,
  GovOSPayAmounts,
  SummaryPaymentObject,
  InvoiceSummaryTransaction,
  InvoiceSummaryLineItem,
} from 'types/govOsPay/govOsPaymentSettings';
import { DEFAULT_GOVOS_PAY_CONVENIENCE_FEE_LABEL } from 'constants/payments';


const createPaymentObject = (
  name: string,
  icon: string,
  paymentType: PaymentTypeT,
  paymentAmounts: PaymentAmounts,
  feeName: string,
  setGovOsPaymentMethodsModalOpen: Dispatch<SetStateAction<boolean>>,
  setSelectedPaymentSummaryType: Dispatch<SetStateAction<PaymentTypeT| null>>
): GovOsPaymentObject => ({
  name,
  icon,
  paymentType,
  paymentAmounts,
  feeName,
  setGovOsPaymentMethodsModalOpen,
  setSelectedPaymentSummaryType,
});


export const calculatePaymentForType = (
  paymentConfig: FormDataGovOSPaymentConfig,
  formState: FormState,
  paymentType: PaymentTypeT,
): [number, number, number] => {
  const { amountSource, achFeeStructure, ccFeeStructure, amountSourceType } = paymentConfig;
  const amount = amountSource || '';
  const feeStructure = paymentType === 'ach' ? achFeeStructure : ccFeeStructure;
  const baseAmount: number = amountSourceType === 'field' ? getFieldAmount(formState, amount) : parseFloat(amount);
  const fee = feeStructure?.fees?.find(f => f.paymentMethodType === paymentType);

  if (baseAmount <= 0) return [0, baseAmount, 0];

  const roundedBaseAmount = Big(baseAmount).round(2).toNumber();
  if (!fee) return [roundedBaseAmount, roundedBaseAmount, 0];

  const totalAmount = [fee].reduce(addFeeType, roundedBaseAmount);
  const feeAmount = Big(totalAmount).minus(roundedBaseAmount).round(2).toNumber();

  return [totalAmount, roundedBaseAmount, feeAmount];
};

export const getGovOSPaymentMethods = (
  paymentConfig: FormDataGovOSPaymentConfig | null | undefined,
  paymentIcons: PaymentMethodsIcons,
  govOsPaymentAmounts: GovOSPayAmounts,
  setGovOsPaymentMethodsModalOpen: Dispatch<SetStateAction<boolean>>,
  setSelectedPaymentSummaryType: Dispatch<SetStateAction<PaymentTypeT | null>>
):GovOsPaymentObject[] => {
  if (!paymentConfig) return [];

  const { achEnabled, ccEnabled } = paymentConfig;
  const { bankAccount, creditCard } = paymentIcons;

  const payments: GovOsPaymentObject[] = [];

  if (achEnabled) {
    const achPayment = createPaymentObject(
      'Bank Account',
      bankAccount,
      'ach',
      govOsPaymentAmounts.achAmounts,
      paymentConfig.achFeeStructure?.fees[0]?.customFeeName || '',
      setGovOsPaymentMethodsModalOpen,
      setSelectedPaymentSummaryType
    );
    payments.push(achPayment);
  }

  if (ccEnabled) {
    const ccPayment = createPaymentObject(
      'Credit Card',
      creditCard,
      'credit_card',
      govOsPaymentAmounts.ccAmounts,
      paymentConfig.ccFeeStructure?.fees[0]?.customFeeName || '',
      setGovOsPaymentMethodsModalOpen,
      setSelectedPaymentSummaryType
    );
    payments.push(ccPayment);
  }

  return payments;
};

export const doGovOsPaymentFlow = (
  govOsPaymentConfig: undefined | FormDataGovOSPaymentConfig,
  paymentAmount: number,
  submitForm: () => void,
  modalStateSetters: ModalStateSetters) => {
  if (!govOsPaymentConfig || paymentAmount === 0) {
    submitForm();
    return;
  }
  const {achEnabled, ccEnabled} = govOsPaymentConfig;

  const {
    setGovOsPaymentMethodsModalOpen,
    setSelectedPaymentSummaryType,
  } = modalStateSetters;
  switch (true) {
    case achEnabled && ccEnabled:
      setGovOsPaymentMethodsModalOpen(true);
      break;
    case achEnabled && !ccEnabled:
      setSelectedPaymentSummaryType('ach');
      break;
    case !achEnabled && ccEnabled:
      setSelectedPaymentSummaryType('credit_card');
      break;
    default:
      return;
  }
};

export const convertFloatToInteger = (amount: number): number =>
  // Multiply by 100 and round to get the integer value
  Math.round(amount * 100);


export const getFilteredPaymentObject = (
  paymentObjects: GovOsPaymentObject[],
  selectedPaymentSummaryType: PaymentTypeT | null): SummaryPaymentObject | null => {
  const filteredObject = paymentObjects.find(obj => obj.paymentType === selectedPaymentSummaryType);

  if (!filteredObject) {
    return null;
  }

  return {
    feeName: filteredObject.feeName,
    name: filteredObject.name,
    icon: filteredObject.icon,
    baseAmount: filteredObject.paymentAmounts.baseAmount.toFixed(2),
    feeTotal: filteredObject.paymentAmounts.feeTotal.toFixed(2),
    totalAmount: filteredObject.paymentAmounts.totalAmount.toFixed(2),
  };
};

export const getTransactionDataForInvoiceSummaryModal = (
  summary: SummaryPaymentObject | null,
  amounts: GovOSPayAmounts,
  accountName: string,
): InvoiceSummaryTransaction[] => {
  if (!summary) {
    return [];
  }
  const baseTransactionLineItems: InvoiceSummaryLineItem[] = [];
  const documentFeeLineItem: InvoiceSummaryLineItem = {
    title: 'Document Fee',
    description: '',
    quantity: 1,
    unitPrice: convertFloatToInteger(parseFloat(summary.baseAmount)),
  };
  baseTransactionLineItems.push(documentFeeLineItem);

  // Add any custom fees to base transaction line items
  if (summary.feeTotal && parseFloat(summary.feeTotal) > 0) {
    const feeLineItem: InvoiceSummaryLineItem = {
      title: summary?.feeName || DEFAULT_GOVOS_PAY_CONVENIENCE_FEE_LABEL,
      description: '',
      quantity: 1,
      unitPrice: convertFloatToInteger(parseFloat(summary.feeTotal)),
    };
    baseTransactionLineItems.push(feeLineItem);
  }

  const transactionData: InvoiceSummaryTransaction[] = [
    {
      entityName: accountName,
      lineItems: baseTransactionLineItems,
    },
  ];

  // If there are digital services, add them as a separate transaction
  if (amounts.digitalServiceFeeAmount > 0) {
    const digitalServiceFeeLineItem: InvoiceSummaryLineItem = {
      title: 'Digital Service Fee',
      description: '',
      quantity: 1,
      unitPrice: convertFloatToInteger(amounts.digitalServiceFeeAmount),
    };
    const digitalServiceFeeTransaction: InvoiceSummaryTransaction = {
      entityName: 'GovOS',
      lineItems: [digitalServiceFeeLineItem],
    };
    transactionData.push(digitalServiceFeeTransaction);
  }

  return transactionData;
};
