import api from 'reducers/api';
import { CLOSE_SNACKBAR } from 'constants/paymentSettings';
import { SAVE_GOVOS_PAYMENT_SETTINGS, GET_FORM_INPUT_FIELDS, GET_FORM_FIELDS_ALL } from 'constants/types/govOsPayTypes';


type State = {
  fields: any[];
  formHasRecaptcha: boolean;
  inputFields: any[];
  isSaveSnackbarOpen: boolean;
};

export const initialState: State = {
  fields: [],
  formHasRecaptcha: false,
  inputFields: [],
  isSaveSnackbarOpen: false,
};

export default function paymentConfiguration(state = initialState, action): State {
  switch (action.type) {
    case SAVE_GOVOS_PAYMENT_SETTINGS:
      return api(action, state, {
        success: () => ({...state, isSaveSnackbarOpen: true}),
        failure: () => ({...state}),
        pending: () => ({...state}),
      });
    case CLOSE_SNACKBAR:
      return {...state, isSaveSnackbarOpen: false};
    case GET_FORM_INPUT_FIELDS:
      return api(action, state, {
        success: (): State => {
          const formFields = action.payload || [];
          return {
            ...state,
            inputFields: formFields,
          };
        },
        failure: () => ({...state}),
        pending: () => ({...state}),
      });
    case GET_FORM_FIELDS_ALL:
      return api(action, state, {
        success: (): State => {
          const formFields = action.payload?.formFields || [];
          return {
            ...state,
            fields: formFields,
            formHasRecaptcha: !!formFields.find(f => f.type === 'recaptcha'),
          };
        },
        failure: () => ({...state}),
        pending: () => ({...state}),
      });
    default:
      return state;
  }
}

export const isSaveSnackbarOpen = state => state.isSaveSnackbarOpen;
export const getFormFields = state => state.fields;
export const getFormInputFields = state => state.inputFields;
export const getFormHasRecaptcha = state => state.formHasRecaptcha;
