import React, { useMemo, memo } from 'react';
import { joinClassNames } from 'utils/strings';
import styles from './FormImage.css';

type ImageAlign = 'left' | 'center' | 'right';

type SpecialSettings = {
  alt?: string;
  fileUrl?: string;
  href?: string;
  imageAlign?: ImageAlign;
}

type Props = {
  id: string;
  specialSettings?: SpecialSettings;
}

const FormImage: React.FC<Props> = ({ id, specialSettings }) => {
  const hasLink = Boolean(specialSettings?.href);

  const image = useMemo(() => (
    <img
      src={specialSettings?.fileUrl}
      alt={specialSettings?.alt || 'form_image'}
      loading='lazy'
    />
  ), [specialSettings?.fileUrl, specialSettings?.alt]);

  const alignmentClass = useMemo(() => {
    switch (specialSettings?.imageAlign) {
      case 'right':
        return styles.AlignRight;
      case 'center':
        return styles.AlignCenter;
      case 'left':
      default:
        return styles.AlignLeft;
    }
  }, [specialSettings?.imageAlign]);

  return (
    <div
      id={`form_image_${id}`}
      className={joinClassNames(styles.Container, alignmentClass)}
    >
      {hasLink ? (
        <a
          href={specialSettings?.href}
          target='_blank'
          rel='noopener noreferrer'
          aria-label={specialSettings?.alt || 'image_link'}
        >
          {image}
        </a>
      ) : (
        image
      )}
    </div>
  );
};

export default memo(FormImage);
