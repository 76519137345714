export const formatDecimalInput = (value: string) => {
  const onlyNumsAndDots = value.replace(/[^\d.]/g, '');

  const splittedDigits = onlyNumsAndDots.split('.');
  if (splittedDigits.length > 2 || (splittedDigits[1] && splittedDigits[1].length > 2)) {
    return [splittedDigits[0], splittedDigits[1].slice(0, 2)].join('.');
  }
  return onlyNumsAndDots;
};

export const formatIntegerInput = (value: string) => {
  const onlyNums = value.replace(/[^\d]/g, '');
  return onlyNums;
};
