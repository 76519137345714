import React, { lazy, Suspense } from 'react';
import { GOVOS_PAY_TRANSACTION_URL, GOVOS_PAY_APPLICATION_NAME, GOVOS_PAY_MULTI_TRANSACTION_URL } from 'constants/govOsPay';
import OverlayModalContainer from '../OverlayModalContainer';
import { convertFloatToInteger } from 'utils/govOSPay/govOsPay';
import { FormDataGovOSPaymentConfig, GovOSPayAmounts } from 'types/govOsPay/govOsPaymentSettings';
import { GovOSPaymentData } from 'types/liveView';
import { FormDataDigitalServiceFeeConfig } from 'types/digitalServiceFees';

const ACHPayment = lazy(() => import('@kofile/platform-react-payrix').then(module => ({ default: module.ACHPayment })));


type ACHPaymentModalProps = {
  open: boolean;
  amounts: GovOSPayAmounts;
  closeModal: () => void;
  paymentCorrelationId: string;
  paymentConfig: FormDataGovOSPaymentConfig | null | undefined;
  onSuccess: (transactionData: GovOSPaymentData) => void,
  digitalServiceFeeConfig: FormDataDigitalServiceFeeConfig | null | undefined;
}


const ACHPaymentModal = (props: ACHPaymentModalProps) => {
  const { open, closeModal, amounts, paymentCorrelationId, paymentConfig, onSuccess, digitalServiceFeeConfig } = props;
  const { providerAccount } = paymentConfig || {};
  const { achAmounts, digitalServiceFeeAmount } = amounts;
  const { totalAmount, baseAmount, feeTotal } = achAmounts;

  const onSuccessHandler = () => {
    onSuccess({
      correlationId: paymentCorrelationId,
      paymentsConfigId: paymentConfig?.paymentsConfigId || '',
      amount: totalAmount,
      baseAmount,
      feeTotal,
      feeAmount: paymentConfig?.achFeeStructure?.fees[0]?.amount || 0,
      feePercentage: paymentConfig?.achFeeStructure?.fees[0]?.percentage || 0,
      paymentType: 'ach',
    });
  };

  if (!open) return null;
  if (!providerAccount) return null;

  const baseTransactionAmountAsInteger = convertFloatToInteger(totalAmount);
  const digitalServiceFeeAmountAsInteger = convertFloatToInteger(digitalServiceFeeAmount);


  const isSplitTransaction = baseAmount > 0 && digitalServiceFeeAmount > 0;
  const amount = isSplitTransaction
    ? baseTransactionAmountAsInteger + digitalServiceFeeAmountAsInteger
    : baseTransactionAmountAsInteger;
  const transactionUrl = isSplitTransaction ? GOVOS_PAY_MULTI_TRANSACTION_URL : GOVOS_PAY_TRANSACTION_URL;
  const splitTransaction = isSplitTransaction ? {
    url: transactionUrl,
    transactions: [
      {
        providerAccountCode: providerAccount.providerAccountCode,
        paymentAmount: baseTransactionAmountAsInteger,
        fees: [],
        lineItems: [],
        isDigitalServiceFee: false,
      },
      {
        providerAccountCode: digitalServiceFeeConfig!.providerAccountCode,
        paymentAmount: digitalServiceFeeAmountAsInteger,
        fees: [],
        lineItems: [],
        isDigitalServiceFee: true,
      },
    ],
  } : undefined;

  return (
    <OverlayModalContainer open={open} closeModal={closeModal}>
      <div>ACH Payment</div>
      <Suspense fallback={<div>{''}</div>}>
        <ACHPayment
          lineItems={[]}
          providerConfig={providerAccount}
          applicationName={GOVOS_PAY_APPLICATION_NAME}
          transactionUrl={isSplitTransaction ? undefined : transactionUrl}
          correlationId={paymentCorrelationId}
          amount={amount}
          onSuccess={onSuccessHandler}
          splitTransaction={splitTransaction}
        />
      </Suspense>
    </OverlayModalContainer>
  );
};

export default ACHPaymentModal;
